/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import localNetworkProfile from '../../../components/session/local-network-profile';

const MODULE_NAME = "percentage-bar-comp";

export function progressBarCalculator(delPercentage) {
  const loopCount = parseInt((delPercentage || 0) / 100, 10);
  const lastBit = delPercentage % 100;
  const loopNums = [];
  for (let i = 0; i < loopCount; i++) {
    loopNums.push(100);
  }
  loopNums.push(lastBit);
  return loopNums;
}

angular.module(MODULE_NAME, [translate, uiBootstrap, localNetworkProfile])

  .component('adnPercentageBar', {
    template: `
      <div ng-repeat="loop in ctrl.loopNums track by $index">
        <div style="text-align: left; position: relative;">
          <span class="progress-bar" role="progressbar" style="width: {{loop || 0}}%; background-color: {{ctrl.color}}">&nbsp;</span>
          <span ng-if="$index === 0" style="position: absolute; left: 2px"><span ng-if="ctrl.deliveryPercentage > ctrl.displayPercentage">&gt;</span>{{ ::ctrl.displayPercentage / 100 | percentage: 2: true }}</span>
        </div>
      </div>
    `,
    controllerAs: 'ctrl',
    bindings: {
      deliveryPercentage: '<',
      maximum: '<',
      color: '@'
    },
    controller: function() {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.color = ctrl.color || '#daebf8';
        let maximum = ctrl.maximum || 1000;
        ctrl.deliveryPercentage = ctrl.deliveryPercentage || 0;
        ctrl.displayPercentage = Math.min(maximum, ctrl.deliveryPercentage);
        ctrl.loopNums = progressBarCalculator(ctrl.displayPercentage);
      };
    }
  });

export default MODULE_NAME;