import _ from "lodash";

export function defaultCurrencyDeriver(ctrl, localUserProfile) {
  const defaultCurrencies = {};
  ctrl.updateCurrencyHook = {};

  return function(advertiser, team, product) {
    const advertiserCurrency = _.get(advertiser, 'defaultCurrency');
    const teamCurrency = _.get(team, 'defaultCurrency');
    const productCurrency = _.get(product, ['minimumBudget', 'currency']) || _.get(product, ['maximumBudget', 'currency']) || _.get(product, ['minimumCpm', 'currency']) || _.get(product, ['maximumBudget', 'currency']);
    const preferredCurrency = localUserProfile.getPreferredCurrency();

    defaultCurrencies['ADVERTISER'] = defaultCurrencies['ADVERTISER'] || advertiserCurrency;
    defaultCurrencies['TEAM'] = defaultCurrencies['TEAM'] || teamCurrency;
    defaultCurrencies['PRODUCT'] = defaultCurrencies['PRODUCT'] || productCurrency;

    ctrl.moneyCurrency = defaultCurrencies['ADVERTISER'] || defaultCurrencies['TEAM'] || defaultCurrencies['PRODUCT'] || preferredCurrency;
    ctrl.chartCurrency = defaultCurrencies['ADVERTISER'] || defaultCurrencies['TEAM'] || defaultCurrencies['PRODUCT'] || preferredCurrency;

    if (_.isFunction(ctrl.updateCurrencyHook.update)) {
      ctrl.updateCurrencyHook.update(ctrl.moneyCurrency);
    }
  };
}
