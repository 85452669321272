/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import localNetworkProfile from '../../../components/session/local-network-profile';

import template from './rate-limit-component.html';

const MODULE_NAME = "rate-limit-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, localNetworkProfile])

  .component('adnRateLimit', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      model: '<',
      rateLimitTypes: '<',
      perUserOnly: '<',
      parentCtrl: '<',
      readOnly: '<readonly'
    },
    controller: function(LocalNetworkProfile, $translate) {
      const ctrl = this;

      ctrl.primaryNetworkCurrency = LocalNetworkProfile.get('defaultCurrency');
      ctrl.hideGlobal = LocalNetworkProfile.isPureMarketplacePlatform();

      const defaultOrder = ['IMPRESSION', 'RENDERED_IMPRESSION', 'VISIBLE_IMPRESSION', 'VIEWABLE_IMPRESSION', 'CLICK', 'CONVERSION', 'BUDGET'];

      ctrl.$onInit = function() {
        ctrl.transSuffix = LocalNetworkProfile.getDefaults().rateLimitTranslations === "2" ? '2' : LocalNetworkProfile.getDefaults().rateLimitTranslations === "3" ? '3' : '';

        ctrl.rateLimitTypesOrdered = _.filter(defaultOrder, function(rateLimit) {
          return ctrl.rateLimitTypes.indexOf(rateLimit) > -1;
        });

        ctrl.deleteLimit = function(index) {
          if (_.get(ctrl, ['parentCtrl', 'editsMade'])) {
            ctrl.parentCtrl.editsMade();
          }
          _.pullAt(ctrl.model.rateLimits, index);
        };

        ctrl.fieldNameTranslated = $translate.instant('lineItem.rateLimit.general' + ctrl.transSuffix + '.title');

        ctrl.addLimit = function() {
          if (_.get(ctrl, ['parentCtrl', 'editsMade'])) {
            ctrl.parentCtrl.editsMade();
          }
          ctrl.model.rateLimits = ctrl.model.rateLimits || [];
          ctrl.model.rateLimits.unshift({
            scope: ctrl.perUserOnly ? 'PER_USER' : (LocalNetworkProfile.getDefaults().rateLimitScope || 'GLOBAL'),
            type: ctrl.rateLimitTypesOrdered[0],
            amount: null,
            periodAmount: 1,
            periodType: 'WEEKS',
            smoothed: !ctrl.perUserOnly
          });
        };
      };
    }
  });

export default MODULE_NAME;