import bulkActionTemplate from "./creative-bulk-action.html";
import _ from "lodash";
import copyInBulkTemplate from "./creative-copy-in-bulk.html";
import {Uuid} from "../../components/util/uuid";
import attachTemplate from "./creative-attach.html";

export const creativeListCommons = function(ctrl, afterFill, LocalNetworkProfile, $state) {
  _.forEach(ctrl.items, function(c) {
    c.previewUrl = $state.href('nude.preview', {creativeId: c.id, networkId: c.network.id, creativeWidth: c.width, creativeHeight: c.height}, {absolute: true});
  });

  if (afterFill) {
    return;
  }

  ctrl.hasObjectSource = LocalNetworkProfile.hasObjectSource();
  ctrl.creativeFields = ['name', {param: 'Layout', label: 'layout'}, {param: '', label: 'parent'}, {label: 'URLs', param: ''}, {label: 'impressionTrackingUrls', param: ''}, {label: 'dimensions', param: 'width'}, {param: '', label: 'info'}, {param: '', label: 'preview'}, 'updateTime', {param: '', label: 'targeting'}];
  if (ctrl.hasObjectSource) {
    ctrl.creativeFields.splice(3, 0, 'source');
  }
  ctrl.showVideoUpload = LocalNetworkProfile.showVideoUpload();

  const skipForDefaults = ['source', 'impressionTrackingUrls', 'targeting'];
  ctrl.defaultFields = _.filter(ctrl.creativeFields, function(f) {
    return skipForDefaults.indexOf(f.label || f.param || f) < 0;
  });
  ctrl.sel = {};
};

export const creativeAttach = function(ctrl, $resources, callback) {
  const $uibModal = $resources.$uibModal;
  const Creative = $resources.Creative;
  const modalInstance = $uibModal.open({
    template: attachTemplate,
    controllerAs: 'modalCtrl',
    windowClass: '',
    controller: function($uibModalInstance, adnModalLoading) {
      const modalCtrl = this;

      modalCtrl.attach = 'LINE_ITEM';
      modalCtrl.hasCreativeSet = ctrl.hasCreativeSet;

      const workingSelections = _.filter(ctrl.bulkSelection, function(val) {
        return !_.get(val, ['creativeSet', 'id']) && !_.get(val, ['lineItem', 'id']);
      });

      const advs = _.uniq(_.filter(_.map(workingSelections, function(val) {
        return _.get(val, ['advertiser']);
      }), function(f) {
        return _.get(f, ['id']);
      }));

      if (advs.length > 1) {
        modalCtrl.noGoodMessage = "The creatives you selected belong to more than one advertiser. You cannot attach all these creatives to the same line item.";
      } else if (advs.length === 1) {
        modalCtrl.adv = advs[0];
      }

      const bulkSelectionLength = Object.keys(ctrl.bulkSelection).length;
      if (workingSelections.length === 0) {
        modalCtrl.noGoodMessage = "All the selected creatives already have a parent attached. These creatives cannot be attached to another parent.";
      } else if (workingSelections.length !== bulkSelectionLength) {
        const dropped = bulkSelectionLength - workingSelections.length;
        modalCtrl.warningMessage = dropped + " of your " + bulkSelectionLength + " selected creatives are already attached to a parent and won't be include in the bulk update.";
      }

      modalCtrl.attachParent = function() {
        const changedObjects = _.map(workingSelections, function(c) {
          const param = modalCtrl.attach === 'LINE_ITEM' ? 'lineItem' : 'creativeSet';
          _.set(c, [param, 'id'], _.get(modalCtrl, [param, 'id']));
          return _.pick(c, ['id', param]);
        });
        Creative.bulkSave(changedObjects).$promise.then(function() {
          ctrl.disabled = false;
          $uibModalInstance.close();
          adnModalLoading.removeModalLoading();
        }, function(errors) {
          ctrl.disabled = false;
          ctrl.validationErrors = errors.data.errors;
          adnModalLoading.removeModalLoading();
        });
      };
    }
  });
  modalInstance.result.then(function(updatedCreatives) {
    callback(updatedCreatives, ctrl.bulkSelection);
  });
};

export const creativeCopyInBulk = function(ctrl, $resources, callback) {
  const $uibModal = $resources.$uibModal;
  const Creative = $resources.Creative;
  const $q = $resources.$q;
  const modalInstance = $uibModal.open({
    template: copyInBulkTemplate,
    controllerAs: 'modalCtrl',
    windowClass: '',
    controller: function($uibModalInstance, adnModalLoading) {
      const modalCtrl = this;

      modalCtrl.attach = 'LINE_ITEM';
      modalCtrl.hasCreativeSet = ctrl.hasCreativeSet;

      const advs = _.uniq(_.filter(_.map(ctrl.bulkSelection, function(val) {
        return _.get(val, ['advertiser']);
      }), function(f) {
        return _.get(f, ['id']);
      }));

      if (advs.length > 1) {
        modalCtrl.noGoodMessage = "The creatives you selected belong to more than one advertiser. You cannot copy these creatives in bulk.";
      } else if (advs.length === 1) {
        modalCtrl.adv = advs[0];
      } else if (Object.keys(ctrl.bulkSelection).length === 0) {
        modalCtrl.noGoodMessage = "No creatives have been selected to copy.";
      }

      modalCtrl.copyInBulk = function() {
        const copyPromises = [];
        _.forEach(ctrl.bulkSelection, function(creative) {
          if (modalCtrl.attach === 'LINE_ITEM') {
            copyPromises.push(Creative.copyCreative(creative, modalCtrl.lineItem, Uuid.generate()));
          } else {
            copyPromises.push(Creative.copyCreative(creative, null, Uuid.generate(), null, modalCtrl.creativeSet));
          }
        });
        $q.all(copyPromises).then(function(updatedCreatives) {
          ctrl.disabled = false;
          $uibModalInstance.close(updatedCreatives);
          adnModalLoading.removeModalLoading();
        });
      };
    }
  });
  modalInstance.result.then(function(updatedCreatives) {
    callback(updatedCreatives, ctrl.bulkSelection);
  });
};

export const postFillGenerateFormatFees = function(ctrl) {
  const validItems = _.filter(ctrl.items, function(item) {
    return !_.find(item.validationWarnings, function(vw) {
      return vw.preventsAdServing;
    });
  });

  const creativeFormatFees = _.map(_.filter(validItems, function(item) {
    return _.get(item.creativeFormatFee, ['monetary', 'amount']) > 0 || _.get(item.creativeFormatFee, ['multiCurrencyMonetaryAmounts', 'length']) > 0;
  }), function(item) {
    return item.creativeFormatFee;
  });

  return {validItems: validItems, creativeFormatFees: creativeFormatFees};
};

export const creativeBulkAction = function(ctrl, $resources) {
  const $uibModal = $resources.$uibModal;

  return function() {
    const modalInstance = $uibModal.open({
      template: bulkActionTemplate,
      controllerAs: 'modalCtrl',
      size: 'lg',
      controller: /*@ngInject*/ function() {
        const modalCtrl = this;

        modalCtrl.item = {};
        modalCtrl.form = {};
        modalCtrl.editing = {};
        modalCtrl.item.name = "{currentName}";
        modalCtrl.item.urlType = 'REPLACE';
        modalCtrl.count = _.keys(ctrl.bulkSelection).length;
        modalCtrl.creativeType = ctrl.creativeType;

        modalCtrl.cancelShareOfVoice = function() {
          modalCtrl.item.sponsorshipPercentage = undefined;
        };

        modalCtrl.copy = function() {

          const creatives = _.map(ctrl.bulkSelection, function(v, k) {
            const obj = {id: k};

            if (modalCtrl.editing.name && modalCtrl.item.name) {
              obj.name = modalCtrl.item.name.replace("{currentName}", v.name);
            }
            if (modalCtrl.editing.userState && modalCtrl.item.userState) {
              obj.userState = modalCtrl.item.userState;
            }
            if (modalCtrl.editing.shareOfVoice) {
              obj.sponsorshipPercentage = modalCtrl.item.sponsorshipPercentage;
            }
            if (modalCtrl.editing.url && modalCtrl.item.url) {
              obj.layoutParameters = obj.layoutParameters || {};
              obj.layoutParameters.specialOverrideUrlParameter = (modalCtrl.item.urlType === 'REPLACE' ? "" : "{url}") + modalCtrl.item.url;
            }

            return obj;
          });

          ctrl.miscBulkAction(modalInstance, modalCtrl, creatives);
        };
      }
    });
  };
};
